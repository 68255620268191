
<template>
  <div class="darkmode-switch" :class="{'darkmode-switch--darkmode':darkmode}" @click="toggle">
    <div class="darkmode-switch__container">
      <div class="darkmode-switch__icon darkmode-switch__icon--left">
        <inline-svg :src="require('../assets/svg/sun.svg')" />
      </div>

      <div class="darkmode-switch__icon darkmode-switch__icon--right">
        <inline-svg :src="require('../assets/svg/moon.svg')" />
      </div>
      <div class="darkmode-switch__toggle" ref="toggle"></div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
export default {
  name: 'DarkmodeSwitch',
  props: {},

  data () {
    return {
      darkmode: false
    }
  },
  watch: {
    darkmode () {
      const pos = this.darkmode ? 4 : 37
      gsap.to(this.$refs.toggle, { ease: 'Expo.easeOut', duration: 0.2, css: { left: pos } })

      this.$store.commit('set_darkmode', this.darkmode)
    }
  },
  methods: {
    toggle () {
      this.darkmode = !this.darkmode

      if (this.darkmode) {
        document.body.classList.add('darkmode')
      } else {
        document.body.classList.remove('darkmode')
      }
    }
  },
  beforeMount () {},
  mounted () {}
}
</script>
