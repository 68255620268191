<template>
  <div id="app">
    <Header></Header>
    <div class="router-view__wrapper">
      <transition name="fade" mode="out-in" appear>
        <router-view class="view" />
      </transition>
    </div>
    <Disclaimer />
    <Footer ref="footer"></Footer>
    <Griddle />
  </div>
</template>

<script>
import gsap from 'gsap'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Disclaimer from '@/components/Disclaimer'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Disclaimer
  },
  props: {
    project: undefined
  },
  watch: {
    $route (now, old) {
      /* if (!document.getElementsByClassName('card__content')[0]) return

      let targetPos = document.getElementsByClassName('card__content')[0].getBoundingClientRect().y + window.scrollY
      if (now.path.indexOf('projects') === -1) targetPos = 0 */

      gsap.to(window, { ease: 'Expo.easeOut', overwrite: true, scrollTo: { y: 0, autoKill: false }, duration: 1 })
      this.$store.commit('set_slug', this.$route.path.replace('/', ''))
    }
  },
  mounted () {
    console.log('%c Hello! ', 'background: #00b9bc; color: #0f0f0f')
    this.$nextTick(() => {
      this.$store.commit('set_slug', this.$route.path.replace('/', ''))
    })
  }
}
</script>

<style lang="scss">
@import "@/scss/index";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

//fade slide
.fadeslide-enter-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fadeslide-leave-active {
  transition: opacity 0.25s ease, transform 0.25s ease;
}

.fadeslide-enter,
.fadeslide-leave-active {
  opacity: 0;
  transform: translateY(10px);
}
</style>
