<template>
  <div class="header">
    <div class="header__bar" ref="bar">
      <span></span>
    </div>

    <div class="header__home">
      <!--<h4 ref="head_1"></h4>-->
      <h1 ref="head_2">MARC STORCH</h1>
      <h3 ref="head_2_1">SENIOR FRONTEND DEVELOPER</h3>
    </div>

    <div class="header__sub">
      <div class="header__sub-row">
        <h4 ref="head_3" class="color-active"><router-link class="custom-router-link" to="/">MARC STORCH</router-link></h4>
        <h4 ref="head_4">Senior Frontend Developer</h4>
        <h4 ref="head_5" class="font-regular"><a class="link" :href="'mailto:'+mail">{{ mail }}</a></h4>
      </div>

      <div class="header__sub-row header__navigation" ref="head_6">
        <div class="header__navigation-item"><router-link to="/">HOME</router-link></div>
        <div class="header__navigation-item"><router-link to="/about">ABOUT</router-link></div>
        <div class="header__navigation-item">
          <router-link
            to="/projects"
            :class="{ 'router-link-exact-active': subIsActive('projects') }"
            >PROJECTS</router-link
          >
        </div>
        <div class="header__navigation-item"><router-link to="/contact">CONTACT</router-link></div>
      </div>
    </div>

    <div class="header__navigation-mobile" ref="mobilenav">
      <div
        class="header__navigation-mobile-trigger"
        :class="{'header__navigation-mobile-trigger--shadow':$scroll.y > 150}"
        @click="toggleMobileNavigation"
      >
        <inline-svg
          ref="mobile_icon"
          :src="require('@/assets/svg/tiny-arrow-up.svg')"
        />
      </div>
      <div class="header__navigation-mobile-background" ref="mobilenavbg"></div>
      <div
        class="header__navigation-mobile-blender"
        ref="mobilenavblender"
      ></div>
      <div class="header__navigation-mobile-contents" ref="mobilenavcontent">
        <Button class="button--center" :class="{ 'hovered': $route.path === '/' }" @click.native="setRoute('/')"
          >HOME</Button
        >
        <Button class="button--center" :class="{ 'hovered': subIsActive('about')}" @click.native="setRoute('/about')"
          >ABOUT</Button
        >

        <Button class="button--center" :class="{ 'hovered': subIsActive('projects') }" @click.native="setRoute('/projects')"
          >PROJECTS</Button
        >
        <Button class="button--center" :class="{ 'hovered': subIsActive('contact')}" @click.native="setRoute('/contact')"
          >CONTACT</Button
        >

      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import gsap from 'gsap'
export default {
  components: { Button },
  name: 'Header',
  props: {
    msg: String
  },
  data () {
    return {
      mail: 'hello@marcstorch.com',
      flipMode: -1,
      currentSlug: 'home',
      mobileNavigationVisible: false,
      newRoute: undefined
    }
  },
  methods: {
    subIsActive (input) {
      if (this.$route.path === input) {
        return true
      }

      return this.$route.path.indexOf(input) !== -1
    },
    setRoute (route) {
      this.newRoute = route

      setTimeout(() => {
        this.mobileNavigationVisible = false
      }, 50)
    },
    toggleMobileNavigation () {
      this.mobileNavigationVisible = !this.mobileNavigationVisible
    },
    checkRoute () {
      if (this.$route.name === 'Home') {
        this.flipMode = 0
      } else {
        this.flipMode = 1
      }
    }
  },
  watch: {

    mobileNavigationVisible () {
      if (this.mobileNavigationVisible) {
        gsap.set('html', { css: { overflow: 'hidden' } })
        gsap.to(this.$refs.mobilenavbg, {
          ease: 'Expo.easeOut',
          y: 0,
          autoAlpha: 1,
          duration: 0.7,
          overwrite: true
        })
        gsap.to(this.$refs.mobilenavcontent, { overwrite: true, autoAlpha: 1, duration: 0.2 })
        gsap.to(this.$refs.mobilenavblender, { overwrite: true, autoAlpha: 0.5, duration: 0.2 })
        gsap.to(this.$refs.mobile_icon.$el, { overwrite: true, rotation: -90, duration: 0.2 })

        const buttons = Array.from(this.$refs.mobilenavcontent.getElementsByClassName('button--center'))

        gsap.to(
          buttons,
          { delay: 0.25, duration: 0.3, overwrite: true, autoAlpha: 1, y: 0, stagger: 0.1, force3D: true }
        )
      } else {
        gsap.set('html', { css: { overflow: '' } })
        gsap.to(this.$refs.mobile_icon.$el, { rotation: 90, duration: 0.2 })

        if (this.newRoute) this.$router.push(this.newRoute)
        this.newRoute = undefined

        gsap.to(
          this.$refs.mobilenavcontent.getElementsByClassName('button--center'),
          { overwrite: true, duration: 0.15, autoAlpha: 0, y: -20, stagger: 0 }
        )

        gsap.to(this.$refs.mobilenavblender, { autoAlpha: 0, duration: 1 })
        gsap.to(this.$refs.mobilenavbg, {
          ease: 'Expo.easeOut',
          y: '-100%',
          duration: 0.5
        })

        gsap.to(this.$refs.mobilenavcontent, {
          autoAlpha: 0,
          duration: 0.5
        })
      }
    },
    flipMode () {
      gsap.to(this.$refs.bar, {
        x: 0,
        ease: 'Expo.easeOut'
      })

      if (this.flipMode === 1) {
        // gsap.to(this.$refs.head_1, { overwrite: true, ease: 'Expo.easeOut', y: '100%', autoAlpha: 0, duration: 0.5 })
        gsap.to(this.$refs.head_2, {
          overwrite: true,
          ease: 'Expo.easeOut',
          y: '30%',
          autoAlpha: 0,
          duration: 0.5
        })

        gsap.to(this.$refs.head_2_1, {
          overwrite: true,
          ease: 'Expo.easeOut',
          y: '30%',
          autoAlpha: 0,
          duration: 0.5
        })

        gsap.to(this.$refs.head_3, {
          overwrite: true,
          ease: 'Expo.easeOut',
          y: '0%',
          autoAlpha: 1,
          duration: 0.7,
          delay: 0.4
        })
        gsap.to(this.$refs.head_4, {
          overwrite: true,
          ease: 'Expo.easeOut',
          y: '0%',
          autoAlpha: 1,
          duration: 0.7,
          delay: 0.4
        })
        gsap.to(this.$refs.head_5, {
          overwrite: true,
          ease: 'Expo.easeOut',
          y: '0%',
          autoAlpha: 1,
          duration: 0.7,
          delay: 0.4
        })
      } else {
        gsap.to(this.$refs.head_3, {
          overwrite: true,
          ease: 'Expo.easeOut',
          y: '-100%',
          autoAlpha: 0,
          duration: 0.5
        })
        gsap.to(this.$refs.head_4, {
          overwrite: true,
          ease: 'Expo.easeOut',
          y: '-100%',
          autoAlpha: 0,
          duration: 0.5
        })
        gsap.to(this.$refs.head_5, {
          overwrite: true,
          ease: 'Expo.easeOut',
          y: '-100%',
          autoAlpha: 0,
          duration: 0.5
        })

        // gsap.to(this.$refs.head_1, { overwrite: true, ease: 'Expo.easeOut', y: '0%', autoAlpha: 1, duration: 0.7, delay: 0.5 })
        gsap.to(this.$refs.head_2, {
          overwrite: true,
          ease: 'Expo.easeOut',
          y: '0%',
          autoAlpha: 1,
          duration: 0.7,
          delay: 0.5
        })

        gsap.to(this.$refs.head_2_1, {
          overwrite: true,
          ease: 'Expo.easeOut',
          y: '0%',
          autoAlpha: 1,
          duration: 0.7,
          delay: 0.51
        })
      }
    },
    $route () {
      this.checkRoute()
      this.currentSlug = this.$route.name.toLowerCase()
      this.mobileNavigationVisible = false
    }
  },
  computed: {},
  mounted () {
    this.$nextTick(() => {
      this.checkRoute()

      gsap.to(
        this.$refs.mobilenavcontent.getElementsByClassName('button--center'),
        { overwrite: true, duration: 0.15, autoAlpha: 0, y: -25, stagger: 0, scale: 1 }
      )

      gsap.to(this.$refs.mobilenavblender, { autoAlpha: 0, duration: 1 })
      gsap.to(this.$refs.mobilenavbg, {
        ease: 'Expo.easeOut',
        y: '-100%',
        duration: 0.5
      })

      gsap.to(this.$refs.mobilenavcontent, {
        autoAlpha: 0,
        duration: 0.5
      })
    })
  }
}
</script>
