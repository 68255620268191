
<template>
    <div class="disclaimer" v-if="display">
        <div ref="container" class="disclaimer__container">
            <div class="disclaimer__wrapper">
                <div class="disclaimer__contents">

                    <h4>DISCLAIMER</h4>
                    <p>
                        I would like to track your movement on this website. Would that be ok?
                    </p>
                    <div class="disclaimer__buttons">
                        <Button @click.native="onAccept">OK</Button><Button @click.native="onDecline">NO WAY</Button>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import gsap from 'gsap'
import Button from '@/components/Button'
export default {
  name: 'Disclaimer',
  props: {},
  components: {
    Button
  },
  data () {
    return {
      display: false
    }
  },
  computed: {
    cookies () {
      return this.$store.getters.cookies
    }
  },
  watch: {
    cookies () {
      if (this.cookies) this.checkDisplay()
    }
  },

  methods: {
    hide () {
      this.$store.commit('set_cookies', false)

      gsap.to(this.$el, { duration: 0.25, autoAlpha: 0 })

      gsap.to(this.$refs.container, { duration: 0.25, yPercent: '0' }).then(() => {
        const hasCookies = this.$cookies.get('ms-cookies-toot') === 'true'

        if (hasCookies) {
          this.$gtm.trackView(this.$route.name, this.$route.fullPath)
        }
        // this.display = false
      })
    },
    onDecline () {
      this.$cookies.set('ms-cookies-toot', false, '1d')
      this.$gtm.enable(false)
      this.hide()
    },
    onAccept () {
      this.$cookies.set('ms-cookies-toot', true, '3y')
      this.$gtm.enable(true)

      this.hide()
    },
    checkDisplay (mounted) {
      let delay = 1
      let timeout = 1000

      this.display = true

      if (!mounted) {
        this.$cookies.remove('ms-cookies-toot')
        timeout = 50
        delay = 0

        console.log('####REMOVING')
      }

      setTimeout(() => {
        const hasCookies = this.$cookies.get('ms-cookies-toot')

        if (hasCookies === null) {
          gsap.to(this.$el, { delay: delay, autoAlpha: 1 })
          gsap.to(this.$refs.container, { ease: 'Expo.easeOut', delay: delay + 0.2, yPercent: '-100' })
        } else {
          if (hasCookies === 'true') {
            this.$gtm.enable(true)
            this.$gtm.trackView(this.$route.name, this.$route.fullPath)
          }
          this.display = false
        }
      }, timeout)
    }

  },
  beforeMount () { },
  mounted () {
    // this.$cookies.remove('ms-cookies-toot')
    this.checkDisplay(true)
  }
}
</script>
