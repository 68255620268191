<template>
  <div class="footer">
    <div class="footer__container">
      <div class="footer__row">
        <DarkmodeSwitch />
        <span
          ><router-link to="/legal">LEGAL NOTICE</router-link>
          <a href="#" @click.prevent="onCookiesClick" >COOKIES</a>
          <router-link to="/contact">CONTACT</router-link></span
        >
      </div>

      <div class="footer__row">
        <span
          ><a @click="scrollToTop"
            >BACK TO TOP
            <inline-svg :src="require('../assets/svg/tiny-arrow-up.svg')" /></a
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import DarkmodeSwitch from './DarkmodeSwitch.vue'
export default {
  name: 'Footer',
  data () {
    return {
      scrollTween: undefined
    }
  },
  components: { DarkmodeSwitch },
  props: {
    msg: String
  },
  methods: {
    onCookiesClick () {
      this.$store.commit('set_cookies', true)
    },
    onMouseWheel () {
      // if (this.scrollTween) this.scrollTween.kill()
      // this.scrollTween = undefined
    },
    scrollToTop (e) {
      e.preventDefault()

      let speed = window.scrollY / 1000
      speed = Math.max(speed, 1)
      speed = Math.min(speed, 2)

      this.scrollTween = gsap.to(window, {
        duration: speed,
        scrollTo: { y: 0, autoKill: true },
        ease: 'Expo.easeOut'
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener('wheel', this.onMouseWheel)
  },
  mounted () {
    window.addEventListener('wheel', this.onMouseWheel)
  }
}
</script>
