<template>
  <div class="button" :class="{ 'button--icon-only': iconOnly ,'button--playful':playful, 'disabled':disabled,'inverted':inverted}">
    <div class="button__icon" v-if="icon">
      <inline-svg :src="require('../assets/svg/' + icon + '.svg')" />
    </div>

      <router-link :to="target" v-if="target" class="button__label"><slot></slot></router-link>
      <span class="button__label" v-else><slot></slot></span>

  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    target: String,
    icon: String,
    iconOnly: undefined,
    playful: undefined,
    disabled: undefined,
    inverted: undefined
  }
}
</script>
