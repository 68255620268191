import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    jsons: [],
    slug: undefined,
    darkmode: undefined,
    active_video: undefined,
    cookies: undefined
  },
  getters: {
    cachedData: (state) => (slug) => {
      return state.jsons[slug]
    },
    slug: (state) => {
      return state.slug
    },
    activeVideo: (state) => {
      return state.active_video
    },
    cookies: (state) => {
      return state.cookies
    }
  },
  mutations: {
    set_data_json (state, json) {
      state.jsons[json.slug] = json.data
    },
    set_slug (state, slug) {
      state.slug = slug
    },
    set_active_video (state, activeVideo) {
      state.active_video = activeVideo
    },
    set_darkmode (state, darkmode) {
      state.darkmode = darkmode
    },
    set_cookies (state, cookies) {
      state.cookies = cookies
    }
  },
  actions: {
  },
  modules: {
  }
})
